import AccountsStats from './modules/Accounts';
import GoalsStats from './modules/Goals';
import MatchesStats from './modules/Matches';
// import ResourcesStats from './modules/Resources';
import StatsReport from './modules/Report';

export default {
  namespaced: false,
  state: () => ({
    contextId: undefined,
  }),
  getters: {
    statsContextId(state, getters, rootState) {
      return state.contextId || rootState.User.companyid;
    },
  },
  mutations: {
    updateStatsContext(state, id) {
      state.contextId = id || state.contextId;
    },
  },
  modules: {
    AccountsStats,
    GoalsStats,
    MatchesStats,
    // ResourcesStats,
    StatsReport,
  },
};
