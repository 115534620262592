export default {
  matchStats(state) {
    return {
      total: state.totalMatches,
      pending: state.totalPendingMatchRequests,
      declines: state.totalDeclinedMatchRequests,
      ended: state.totalEndedMatches,
    };
  },
};
