import {
  apiStore, apiPost, generateFormData,
} from '../../../../modules/apiHelper';
import {
  isStaleData, invalideDate, objectIsValid, now,
} from '../../../../modules/dataHelper';
import resolve from '../../../../modules/api/resolve';


export default {
  async matchStats(context) {
    const companyid = context.rootGetters.statsContextId;
    // xhr then update store & vuex
    const request = resolve(apiPost('match_stats', generateFormData({ companyid }), 7))
      .catch((err) => {
        console.warn('matchStats::match_stats err', err);
        return false;
      })
      .then((res) => {
        if (!res) return false;

        const data = {
          totalMatches: res.Total_matches,
          totalEndedMatches: res.match_ended,
          totalPendingMatchRequests: res.match_requests,
          totalDeclinedMatchRequests: res.match_decline,
        };

        context.commit('updateMatStats', data);

        return true;
      });

    await request;
    return request;
  },
  // Uses Matches::getDelayedMatchRequests()
  // Makes sense of the getDelayedMatchRequests() action
  // [UPDATE] Might just list the delayed requests. Not sure
  async delayedMatchRequests(context) {
    const accounts = await apiStore.getItem('stats/delayedmatchrequests') || [invalideDate, {}];
    // turn the data to stats
    const request = context.dispatch('getDelayedMatchRequests', null, { root: true })
      .then((res) => {
        if (!res) return false;

        const data = {
          total: res.length,
        };

        apiStore.setItem('stats/delayedmatchrequests', [now(), data]);

        return data;
      });

    if (!isStaleData(accounts[0]) && objectIsValid(accounts[1])) return accounts[1];

    // wait for the xhr
    const result = await request;
    return result;
  },
};
