export default {
  tasksStats(state) {
    return {
      total: state.totalTasksCreated,
      new: {
        today: state.tasksCreatedToday,
        thisWeek: state.tasksCreatedThisWeek,
        thisMonth: state.tasksCreatedThisMonth,
      },
      completedTasks: state.totalTasksCompleted,
      ongoingTasks: state.totalTasksOngoing,
      overdueTasks: state.totalTasksOverdue,
    };
  },
};
