import { apiPost, generateFormData } from '../../../../../modules/apiHelper';
import resolve from '../../../../../modules/api/resolve';

const STATS = {
  totalTasksCreated: 99,
  tasksCreatedToday: 99,
  tasksCreatedThisWeek: 99,
  tasksCreatedThisMonth: 99,
  totalTasksOngoing: 99,
  totalTasksOverdue: 99,
};

/**
 * TODO: This is a bad API implementation. Didn't ask for a graph type of stat
 * @returns {Object} {totalCreated[today:Number,thisWeek:Number,thisMonth:Number],totalOverDue:Number,totalOngoing:Number, totalCompleted:Number}
 */
export default async function (companyid) {
  // overdue, ongoing & completed
  const data1 = resolve(apiPost('company_task_stat', generateFormData({ companyid }), 7))
    .then((res) => {
      if (!res) return false;

      STATS.totalTasksCreated = res.total_tasks;
      STATS.tasksCreatedThisWeek = res.weekly_tasks;
      STATS.tasksCreatedThisMonth = res.monthly_tasks;

      STATS.totalTasksOverdue = res.total_overdue_tasks;
      STATS.totalTasksOngoing = res.total_ongoing;
      STATS.totalTasksCompleted = res.total_complete;

      return true;
    });

  const result = await Promise.all([data1])
    .then((res) => {
      if (res.some((r) => !!r === false)) return false;
      return STATS;
    });

  return result;
}
