import { apiStore } from '../../../../modules/apiHelper';
import {
  // eslint-disable-next-line no-unused-vars
  now, isStaleData, invalideDate, objectIsValid,
} from '../../../../modules/dataHelper';
import getSelectedGoalsStats from './_helpers/getSelectedGoalsStats';
import getSelectedSubGoalsStats from './_helpers/getSelectedSubGoalsStats';
import getTasksStats from './_helpers/getTasksStats';

export default {
  async tasksStats(context) {
    const statsContext = context.rootGetters.statsContextId;
    // const stats = await apiStore.getItem('stats/tasksstats') || [invalideDate, {}];
    const request = getTasksStats(statsContext)
      .then((res) => {
        // console.log(res);
        if (!res) return false;

        // apiStore.setItem('stats/tasksstats', res);

        context.commit('updateTasStats', res);

        return res;
      }); // xhr then update store

    //
    // if (!isStaleData(stats[0], now(), 432000000) && objectIsValid(stats[1])) {
    //   context.commit('updateTasStats', stats[1]);
    // } else {
    // }

    await request;
    return request;
  },

  // data is stale after 10 hours (36000000)
  async selectedGoalsStats(context) {
    const statsContext = context.rootGetters.statsContextId;
    const apiStoreName = `stats/goalsstats/${statsContext}`;

    // const stats = await apiStore.getItem(apiStoreName) || [invalideDate, {}];
    const request = getSelectedGoalsStats(statsContext)
      .then((res) => {
        if (!res) return false;

        const data = {
          labels: res.map((r) => r.goal),
          data: res.map((r) => Number(r.stat)),
        };

        apiStore.setItem(apiStoreName, data);

        return data;
      }); // xhr then cache

    //
    // if (!isStaleData(stats[0], now(), 36000000) && objectIsValid(stats[1])) return stats[1];

    // wait for the xhr
    const result = await request;
    return result;
    // return staticUsers;
  },

  // data is stale after 10 hours (36000000)
  async selectedSubGoalsStats(context) {
    const statsContext = context.rootGetters.statsContextId;
    // const apiStoreName = `stats/subgoalstats/${statsContext}`;
    // const stats = await apiStore.getItem(apiStoreName) || [invalideDate, {}];

    const request = getSelectedSubGoalsStats(statsContext)
      .then((res) => {
        if (!res) return false;

        const data = {
          labels: res.map((r) => r.subgoal),
          data: res.map((r) => Number(r.stat)),
        };

        // apiStore.setItem(apiStoreName, data);
        return data;
      }); // xhr then cache

    //
    // if (!isStaleData(stats[0], now(), 36000000) && objectIsValid(stats[1])) return stats[1];

    // wait for the xhr
    const result = await request;
    return result;
    // return staticUsers;
  },
};
