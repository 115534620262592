import { apiPost, generateFormData } from '../../../../../modules/apiHelper';
import resolve from '../../../../../modules/api/resolve';

const statEndpoint = 'comp-subgoal-stats';
const goalsEndpoint = 'company-subgoals';

export default async function (companyid) {
  const goals = await resolve(apiPost(goalsEndpoint, generateFormData({ companyid }), 7));

  // console.log(goals);

  if (!goals) return false;

  const stats = resolve(apiPost(statEndpoint, generateFormData({ companyid }), 7))
    .then((res) => {
      if (!res) return false;

      const s = [];

      (res[statEndpoint] || []).forEach(({ id, stat }) => {
        const subgoal = goals[goalsEndpoint].find((g) => Number(g.id) === Number(id));

        if (subgoal) {
          s.push({
            subgoal: subgoal.goal,
            stat,
          });
        }
      });

      return s;
    });

  return stats;
}
