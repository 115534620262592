export default {
  state: () => ({
    totalTasksCreated: 0,
    tasksCreatedToday: 0,
    tasksCreatedThisWeek: 0,
    tasksCreatedThisMonth: 0,

    totalTasksCompleted: 0,
    totalTasksOngoing: 0,
    totalTasksOverdue: 0,
  }),
};
