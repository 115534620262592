export default {
  state: () => ({
    mentors: {
      total: 0,
      new: {
        today: 0,
        thisWeek: [],
        thisMonth: [],
      },
    },

    mentees: {
      total: 0,
      new: {
        today: 0,
        thisWeek: [],
        thisMonth: [],
      },
    },
  }),

};
