import { generateFormData, apiPost } from '../../../../../modules/apiHelper';
import resolve from '../../../../../modules/api/resolve';

const APIS = {
  mentors: {
    thisWeek: 'company_mentors',
    thisMonth: 'company_mentors_mnth',
  },
  mentees: {
    thisWeek: 'company_mentees',
    thisMonth: 'company_mentees_mnth',
  },
};

export default async function (userType = 'mentors', companyid) {
  const USER_STATS = {
    today: 0,
    thisWeek: [],
    thisMonth: [],
  };

  const userTypeLowercase = userType.toLowerCase();
  const formData = generateFormData({ companyid });
  const requests = [
    resolve(apiPost(APIS[userTypeLowercase].thisWeek, formData, 10)),
    resolve(apiPost(APIS[userTypeLowercase].thisMonth, formData, 10)),
  ];

  const response = await Promise.all(requests)
    .then(([resThisWeek, resThisMonth]) => {
      // console.log(resThisWeek[APIS[userTypeLowercase].thisWeek]);
      if (resThisWeek) {
        USER_STATS.thisWeek = (resThisWeek[APIS[userTypeLowercase].thisWeek] || []).map((curr) => Number(curr[userTypeLowercase]));
      }
      // console.log(USER_STATS.thisWeek);

      if (resThisMonth) {
        USER_STATS.thisMonth = (resThisMonth[APIS[userTypeLowercase].thisMonth] || []).map((curr) => Number(curr[userTypeLowercase]));
      }

      return { ...USER_STATS };
    });

  return response;
}
