export default {
  updateTasStats(state, stats) {
    state.totalTasksCreated = Number(stats.totalTasksCreated) || 0;
    state.tasksCreatedToday = Number(stats.tasksCreatedToday) || 0;
    state.tasksCreatedThisWeek = Number(stats.tasksCreatedThisWeek) || 0;
    state.tasksCreatedThisMonth = Number(stats.tasksCreatedThisMonth) || 0;

    state.totalTasksCompleted = Number(stats.totalTasksCompleted) || 0;
    state.totalTasksOngoing = Number(stats.totalTasksOngoing) || 0;
    state.totalTasksOverdue = Number(stats.totalTasksOverdue) || 0;
  },
};
