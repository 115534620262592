import { apiStore, apiPost } from '../../../../modules/apiHelper';
import {
  now, isStaleData, invalideDate, objectIsValid,
} from '../../../../modules/dataHelper';
import getUserStats from './_helpers/getUserStats';


export default {
  /**
   *
   * @param {Object} context VUEX
   * @param {String} userType [Mentors, Mentees]
   */
  async userTypeAccStats(context, userType) {
    const userTypeLowercase = userType.toLowerCase();
    const statsContext = context.rootGetters.statsContextId;

    // const apiStoreName = `stats/${statsContext}/${userTypeLowercase}`;
    // const stats = await apiStore.getItem(apiStoreName) || [invalideDate, {}];
    // xhr then update store & vuex
    const request = getUserStats(userTypeLowercase, statsContext)
      .catch((err) => {
        console.warn('getAccounts::accounts err', err);
        return false;
      })
      .then(async (userStats) => {
        if (!userStats) return false;

        // console.log(userType, ': ', userStats);
        // return false;
        // eslint-disable-next-line no-unreachable
        const accounts = await context.dispatch(
          'getAccounts',
          [
            // mentor || mentee
            userTypeLowercase.substring(0, userTypeLowercase.length - 1),
            undefined,
            statsContext,
          ],
          { root: true },
        );

        // console.log(accounts);

        const data = {
          total: accounts.length,
          new: userStats,
        };

        // apiStore.setItem(apiStoreName, [now(), data]);

        context.commit('updateProp', [userTypeLowercase, data]);

        return userStats;
      });

    // if (!isStaleData(stats[0]) && Object.keys(stats[1]).length > 0) {
    //   // context.commit('updateProp', [userTypeLowercase, stats[1]]);
    // } else {
    // }
    await request;
    return request;
  },

  async topUserTypes(context, [userType, fetchNew = false]) {
    // eslint-disable-next-line no-unused-vars
    const APIS = {
      mentors: '',
      mentees: '',
    };

    const topUserTypes = await apiStore.getItem(`stats/top${userType.toLowerCase()}`) || [invalideDate, []];
    const request = apiPost; // xhr then update store

    if (!isStaleData(topUserTypes[0]) && !fetchNew && topUserTypes[1].length > 0) return topUserTypes[1];

    // wait for the xhr
    // eslint-disable-next-line no-unused-vars
    const result = await request;
    // return result;
    return [];
  },

  // stale after 5 hours (18000000)
  // [UPDATE] Uses Accounts::getAccounts()
  // [UPDATE 2] Makes sense of Accounts::getAccounts() action
  async dormantAccountsStats(context, fetchNew = false) {
    const statsContext = context.rootGetters.statsContextId;
    const apiStoreName = `stats/dormantaccounts/${statsContext}`;
    const accounts = await apiStore.getItem(apiStoreName) || [invalideDate, {}];

    const request = Promise.all([
      context.dispatch('getAccounts', ['mentor', 'dormant', statsContext], { root: true }),
      context.dispatch('getAccounts', ['mentee', 'dormant', statsContext], { root: true }),
      context.dispatch('getAccounts', ['staff', 'dormant', statsContext], { root: true }),
    ])
      .then(([mentorRes, menteeRes, staffRes]) => {
        if (!mentorRes || !menteeRes || !staffRes) return false;

        const data = {
          mentors: mentorRes.length,
          mentees: menteeRes.length,
          staff: staffRes.length,
        };

        // console.log(data);
        // update data then cache

        apiStore.setItem(apiStoreName, [now(), data]);

        return data;
      }); // xhr then update store

    if (!isStaleData(accounts[0], now(), 18000000) && !fetchNew && objectIsValid(accounts[1])) return accounts[1];

    // wait for the xhr
    const result = await request;
    return result;
  },
};
